var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "video" }, [
    _vm.showVideo
      ? _c("video", {
          staticClass: "videolist",
          attrs: { id: _vm.videoData.id, controls: "", muted: "" },
          domProps: { muted: true },
          on: { click: _vm.screenProjection },
        })
      : _c("div", { staticClass: "videolist", attrs: { id: _vm.eleId } }, [
          _c("img", {
            staticClass: "image-logo",
            attrs: { src: require("@/assets/noVideo.png") },
          }),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }