var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "monitor-screen" }, [
    !_vm.viewCompany
      ? _c("div", { staticClass: "header" }, [
          _c("span", { staticClass: "header-title" }, [
            _vm._v("中材国际智慧安全监控大屏"),
          ]),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "content-container" },
      [
        !_vm.viewCompany
          ? _c("div", { staticClass: "left-container" }, [
              _c("div", { staticClass: "earth-content" }, [
                _c("div", { staticClass: "earth-content-title" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/largeScreen/earth-top-title.png"),
                      alt: "",
                    },
                  }),
                  _c("p", [
                    _vm._v("\n            国外项目/工厂"),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.superPageData["projectsInsideAndOutside"] &&
                            _vm.superPageData["projectsInsideAndOutside"][
                              "国外项目"
                            ]
                        )
                      ),
                    ]),
                    _vm._v("个\n          "),
                  ]),
                ]),
                _c("div", { staticClass: "earth-content-ball" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/largeScreen/earth-ball.png"),
                      alt: "地球",
                    },
                  }),
                  _c("span", { staticClass: "state ns" }, [
                    _vm._v(
                      "北美洲" +
                        _vm._s(
                          _vm.superPageData.projectsOutside &&
                            _vm.superPageData.projectsOutside["北美洲"]
                        ) +
                        "个"
                    ),
                  ]),
                  _c("span", { staticClass: "state as" }, [
                    _vm._v(
                      "亚洲" +
                        _vm._s(
                          _vm.superPageData.projectsOutside &&
                            _vm.superPageData.projectsOutside["亚洲"]
                        ) +
                        "个"
                    ),
                  ]),
                  _c("span", { staticClass: "state eu" }, [
                    _vm._v(
                      "欧洲" +
                        _vm._s(
                          _vm.superPageData.projectsOutside &&
                            _vm.superPageData.projectsOutside["欧洲"]
                        ) +
                        "个"
                    ),
                  ]),
                  _c("span", { staticClass: "state af" }, [
                    _vm._v(
                      "非洲" +
                        _vm._s(
                          _vm.superPageData.projectsOutside &&
                            _vm.superPageData.projectsOutside["非洲"]
                        ) +
                        "个"
                    ),
                  ]),
                  _c("span", { staticClass: "state oc" }, [
                    _vm._v(
                      "大洋洲" +
                        _vm._s(
                          _vm.superPageData.projectsOutside &&
                            _vm.superPageData.projectsOutside["大洋洲"]
                        ) +
                        "个"
                    ),
                  ]),
                  _c("span", { staticClass: "state sa" }, [
                    _vm._v(
                      "南美洲" +
                        _vm._s(
                          _vm.superPageData.projectsOutside &&
                            _vm.superPageData.projectsOutside["南美洲"]
                        ) +
                        "个"
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "earth-content-desc" }, [
                  _c("div", { staticClass: "earth-content-desc-container" }, [
                    _c("div", [
                      _c("p", { staticClass: "square as-bg" }, [
                        _vm._v("\n                亚洲区域 "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.superPageData.projectsOutside &&
                                _vm.superPageData.projectsOutside["亚洲"]
                            )
                          ),
                        ]),
                        _vm._v(" 个\n              "),
                      ]),
                      _c("p", { staticClass: "square af-bg" }, [
                        _vm._v("\n                非洲区域 "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.superPageData.projectsOutside &&
                                _vm.superPageData.projectsOutside["非洲"]
                            )
                          ),
                        ]),
                        _vm._v(" 个\n              "),
                      ]),
                      _c("p", { staticClass: "square eu-bg" }, [
                        _vm._v("\n                欧洲区域 "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.superPageData.projectsOutside &&
                                _vm.superPageData.projectsOutside["欧洲"]
                            )
                          ),
                        ]),
                        _vm._v(" 个\n              "),
                      ]),
                    ]),
                    _c("div", [
                      _c("p", { staticClass: "square sa-bg" }, [
                        _vm._v(
                          "\n                南美洲区域\n                "
                        ),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.superPageData.projectsOutside &&
                                _vm.superPageData.projectsOutside["南美洲"]
                            )
                          ),
                        ]),
                        _vm._v(" 个\n              "),
                      ]),
                      _c("p", { staticClass: "square na-bg" }, [
                        _vm._v(
                          "\n                北美洲区域\n                "
                        ),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.superPageData.projectsOutside &&
                                _vm.superPageData.projectsOutside["北美洲"]
                            )
                          ),
                        ]),
                        _vm._v(" 个\n              "),
                      ]),
                      _c("p", { staticClass: "square oc-bg" }, [
                        _vm._v(
                          "\n                大洋洲区域\n                "
                        ),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.superPageData.projectsOutside &&
                                _vm.superPageData.projectsOutside["大洋洲"]
                            )
                          ),
                        ]),
                        _vm._v(" 个\n              "),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        !_vm.viewCompany
          ? _c("div", { staticClass: "center-container" }, [
              _vm._m(0),
              _vm._m(1),
              _c(
                "div",
                {
                  staticClass: "company-list-container",
                  on: {
                    click: function ($event) {
                      return _vm.clickItem($event)
                    },
                  },
                },
                _vm._l(_vm.scrollData, function (item, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      staticClass: "company-list-item",
                      attrs: { "data-obj": JSON.stringify(item) },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "company-list-item-top",
                          attrs: { "data-obj": JSON.stringify(item) },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { "data-obj": JSON.stringify(item) } },
                            [_vm._v(_vm._s(item.count))]
                          ),
                        ]
                      ),
                      _c(
                        "el-tooltip",
                        { attrs: { content: item.name, placement: "bottom" } },
                        [
                          _c(
                            "p",
                            { attrs: { "data-obj": JSON.stringify(item) } },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
        !_vm.viewCompany
          ? _c("div", { staticClass: "right-container" }, [
              _c("div", { staticClass: "earth-content" }, [
                _c("div", { staticClass: "earth-content-title" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/largeScreen/earth-top-title.png"),
                      alt: "",
                    },
                  }),
                  _c("p", [
                    _vm._v("\n            国内项目/工厂"),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.superPageData["projectsInsideAndOutside"] &&
                            _vm.superPageData["projectsInsideAndOutside"][
                              "国内项目"
                            ]
                        )
                      ),
                    ]),
                    _vm._v("个\n          "),
                  ]),
                ]),
                _c("div", { staticClass: "earth-map" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/largeScreen/earth-map.png"),
                      alt: "中国地图",
                    },
                  }),
                  _c("span", { staticClass: "state ns" }, [
                    _vm._v(
                      "西北" +
                        _vm._s(
                          _vm.superPageData.projectsInside &&
                            _vm.superPageData.projectsInside["西北"]
                        ) +
                        "个"
                    ),
                  ]),
                  _c("span", { staticClass: "state as" }, [
                    _vm._v(
                      "华北" +
                        _vm._s(
                          _vm.superPageData.projectsInside &&
                            _vm.superPageData.projectsInside["华北"]
                        ) +
                        "个"
                    ),
                  ]),
                  _c("span", { staticClass: "state eu" }, [
                    _vm._v(
                      "东北" +
                        _vm._s(
                          _vm.superPageData.projectsInside &&
                            _vm.superPageData.projectsInside["东北"]
                        ) +
                        "个"
                    ),
                  ]),
                  _c("span", { staticClass: "state af" }, [
                    _vm._v(
                      "华中" +
                        _vm._s(
                          _vm.superPageData.projectsInside &&
                            _vm.superPageData.projectsInside["华中"]
                        ) +
                        "个"
                    ),
                  ]),
                  _c("span", { staticClass: "state oc" }, [
                    _vm._v(
                      "华东" +
                        _vm._s(
                          _vm.superPageData.projectsInside &&
                            _vm.superPageData.projectsInside["华东"]
                        ) +
                        "个"
                    ),
                  ]),
                  _c("span", { staticClass: "state ws" }, [
                    _vm._v(
                      "西南" +
                        _vm._s(
                          _vm.superPageData.projectsInside &&
                            _vm.superPageData.projectsInside["西南"]
                        ) +
                        "个"
                    ),
                  ]),
                  _c("span", { staticClass: "state sa" }, [
                    _vm._v(
                      "华南" +
                        _vm._s(
                          _vm.superPageData.projectsInside &&
                            _vm.superPageData.projectsInside["华南"]
                        ) +
                        "个"
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "earth-content-desc" }, [
                  _c(
                    "div",
                    { staticClass: "earth-content-desc-container ml" },
                    [
                      _c("div", [
                        _c("p", { staticClass: "square as-bg" }, [
                          _vm._v("\n                西北区域 "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.superPageData.projectsInside &&
                                  _vm.superPageData.projectsInside["西北"]
                              )
                            ),
                          ]),
                          _vm._v(" 个\n              "),
                        ]),
                        _c("p", { staticClass: "square af-bg" }, [
                          _vm._v("\n                东北区域 "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.superPageData.projectsInside &&
                                  _vm.superPageData.projectsInside["东北"]
                              )
                            ),
                          ]),
                          _vm._v(" 个\n              "),
                        ]),
                        _c("p", { staticClass: "square eu-bg" }, [
                          _vm._v("\n                华北区域 "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.superPageData.projectsInside &&
                                  _vm.superPageData.projectsInside["华北"]
                              )
                            ),
                          ]),
                          _vm._v(" 个\n              "),
                        ]),
                      ]),
                      _c("div", [
                        _c("p", { staticClass: "square sa-bg" }, [
                          _vm._v("\n                华东区域 "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.superPageData.projectsInside &&
                                  _vm.superPageData.projectsInside["华东"]
                              )
                            ),
                          ]),
                          _vm._v(" 个\n              "),
                        ]),
                        _c("p", { staticClass: "square na-bg" }, [
                          _vm._v("\n                华中区域 "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.superPageData.projectsInside &&
                                  _vm.superPageData.projectsInside["华中"]
                              )
                            ),
                          ]),
                          _vm._v(" 个\n              "),
                        ]),
                        _c("p", { staticClass: "square oc-bg" }, [
                          _vm._v("\n                华南区域 "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.superPageData.projectsInside &&
                                  _vm.superPageData.projectsInside["华南"]
                              )
                            ),
                          ]),
                          _vm._v(" 个\n              "),
                        ]),
                      ]),
                      _c("div", { staticClass: "item-left" }, [
                        _c("p", { staticClass: "square ws-bg" }, [
                          _vm._v("\n                西南区域 "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.superPageData.projectsInside &&
                                  _vm.superPageData.projectsInside["西南"]
                              )
                            ),
                          ]),
                          _vm._v(" 个\n              "),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.viewCompany
          ? _c("project-map", { on: { backScreen: _vm.clickBackBtn } })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "map-title" }, [
      _c("span", [_vm._v("监控大屏")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "map-container" }, [
      _c("div", { staticClass: "map-box", attrs: { id: "center_map" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }