var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.videoShow
    ? _c("div", { attrs: { id: "video-list-container" } }, [
        _c("div", {
          staticClass: "video-container",
          attrs: { id: "video-container1" },
        }),
        _c("div", {
          staticClass: "video-container",
          attrs: { id: "video-container2" },
        }),
        _c("div", {
          staticClass: "video-container",
          attrs: { id: "video-container3" },
        }),
        _c("div", {
          staticClass: "video-container",
          attrs: { id: "video-container4" },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }