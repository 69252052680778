var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "project-map flex-container" }, [
    _c("section", { staticClass: "left-section" }, [
      _c("div", { staticClass: "flex-container flex-align-c" }, [
        _c("span", {
          staticClass: "el-icon-arrow-left back-btn",
          on: { click: _vm.backScreen },
        }),
        _c("span", { staticClass: "header-title" }, [
          _vm._v("中材国际智慧安全监控大屏"),
        ]),
      ]),
      _c("div", { staticClass: "project-container" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-title" }, [
            _c("span", { staticClass: "card-title-name" }, [
              _vm._v("国内项目"),
            ]),
            _c("span", { staticClass: "card-title-quantity" }, [
              _vm._v(_vm._s(_vm.innerCountry.length) + "个"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "card-content" },
            _vm._l(_vm.innerCountry, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  class: [
                    "card-content-item",
                    _vm.chooseItem.id === item.id ? "active" : "",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.selProjectItem(item)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      class: [
                        "card-content-item-title",
                        item.cameraNum > 0 ? "online" : "offline",
                      ],
                      attrs: { title: item.name },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(item.name) +
                          "\n            "
                      ),
                    ]
                  ),
                  _c("div", {
                    class:
                      item.cameraNum > 0
                        ? "card-content-item__online"
                        : "card-content-item__offline",
                  }),
                ]
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-title" }, [
            _c("span", { staticClass: "card-title-name" }, [
              _vm._v("国外项目"),
            ]),
            _c("span", { staticClass: "card-title-quantity" }, [
              _vm._v(_vm._s(_vm.outerCountry.length) + "个"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "card-content" },
            _vm._l(_vm.outerCountry, function (item1, index1) {
              return _c(
                "div",
                {
                  key: index1,
                  class: [
                    "card-content-item",
                    _vm.chooseItem.id === item1.id ? "active" : "",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.selProjectItem(item1)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      class: [
                        "card-content-item-title",
                        item1.cameraNum > 0 ? "online" : "offline",
                      ],
                      attrs: { title: item1.name },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(item1.name) +
                          "\n            "
                      ),
                    ]
                  ),
                  _c("div", {
                    class:
                      item1.cameraNum > 0
                        ? "card-content-item__online"
                        : "card-content-item__offline",
                  }),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "right-section" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.chooseItem.id,
              expression: "chooseItem.id",
            },
          ],
          staticClass: "camera-list-container",
        },
        [
          _vm._m(0),
          _c(
            "div",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.load,
                  expression: "load",
                },
              ],
              staticClass: "video-name infinite-list",
              staticStyle: { overflow: "auto" },
            },
            [
              _vm._l(_vm.videoNameList, function (item, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "video-name-list_item infinite-list-item",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "video-mouse-H",
                        on: {
                          mouseenter: function ($event) {
                            return _vm.enterName(i, item.status, item)
                          },
                          mouseleave: _vm.leaveName,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showVideoBtn && i == _vm.indCur,
                                expression: "showVideoBtn && i == indCur",
                              },
                            ],
                            staticClass: "video-name-list_item-btn",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn-p",
                                attrs: { type: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.video_P1(item)
                                  },
                                },
                              },
                              [_vm._v("p1")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn-p",
                                attrs: { type: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.video_P2(item)
                                  },
                                },
                              },
                              [_vm._v("p2")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn-p",
                                attrs: { type: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.video_P3(item)
                                  },
                                },
                              },
                              [_vm._v("p3")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn-p",
                                attrs: { type: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.video_P4(item)
                                  },
                                },
                              },
                              [_vm._v("p4")]
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.selP,
                                expression: "item.selP",
                              },
                            ],
                            staticClass: "item_name_1",
                            staticStyle: {
                              color: "green",
                              "margin-right": "4px",
                            },
                          },
                          [_vm._v(_vm._s(item.selP))]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "item_name_1",
                            attrs: { title: item.cameraName },
                          },
                          [_vm._v(_vm._s(item.cameraName))]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isEnd,
                      expression: "isEnd",
                    },
                  ],
                  staticClass: "video-name-list_item infinite-list-item",
                },
                [_vm._m(1)]
              ),
            ],
            2
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "big-video" },
        [_c("VideoOnePro", { attrs: { BigVideoData: _vm.BigVideoData } })],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.chooseItem.id,
              expression: "chooseItem.id",
            },
          ],
          staticClass: "small-video",
        },
        [
          _c(
            "div",
            { staticClass: "top-video" },
            [
              _vm.videoS_TypeObj.sysType === 0
                ? _c("hk-player-web-control", {
                    ref: "HkPlayerWebControl",
                    on: { oWebControl: _vm.oWebControl },
                  })
                : _vm._e(),
              _vm.videoS_TypeObj.sysType === 1
                ? _c("ysy-video-player", {
                    ref: "ysyVideoPlayer",
                    on: { oWebControl: _vm.oWebControl },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c("div", { attrs: { id: "project-map-container" } }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "camera-list" }, [
      _c("h5", [_vm._v("摄像头列表")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "video-mouse-H justify-content" }, [
      _c("div", { staticClass: "video-name-list_item-btn" }, [
        _c("div", { staticClass: "item_name_1" }, [_vm._v("到底了~~")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }