<template>
  <div id="video-list-container" v-if="videoShow">
    <div class="video-container" id="video-container1"></div>
    <div class="video-container" id="video-container2"></div>
    <div class="video-container" id="video-container3"></div>
    <div class="video-container" id="video-container4"></div>
  </div>
</template>

<script>
import { $_getCameraUrl } from "@/api/projectManagement/projectManagement";

export default {
  name: "ysyVideoPlayer",
  components: {},
  data() {
    return {
      player1: null,
      player2: null,
      player3: null,
      player4: null,
      container: null,
      containerWidth: 0,
      containerHeight: 0,
      videoShow: true,
    };
  },
  computed: {},
  mounted() {
    this.container = document.getElementById("video-list-container");
    this.containerWidth = this.container.offsetWidth;
    this.containerheight = this.container.offsetHeight;
  },
  methods: {
    /**
     * 直接触发视频加载
     */
    initPlugin() {
      this.$emit("oWebControl");
    },

    /**
     * 播放视频
     */
    play(index, item) {
      let _this = this;
      $_getCameraUrl({ cameraId: item.id, protocol: 1, quality: 2 })
        .then((res) => {
          let playUrl = res?.data?.data?.url
            ? res?.data?.data?.url
            : `ezopen://open.ys7.com/${item.cameraCode}/${item.channelNo}.live`;
          this["player" + index + 1] = new EZUIKit.EZUIKitPlayer({
            id: `video-container${index + 1}`, // 视频容器ID
            accessToken: sessionStorage.getItem("appToken"),
            url: playUrl,
            // simple - 极简版; pcLive-pc直播；pcRec-pc回放；mobileLive-移动端直播；mobileRec-移动端回放;security - 安防版;voice-语音版;
            template: "security",
            plugin: ["talk"], // 加载插件，talk-对讲
            footer: ["hd", "fullScreen"],
            width: _this.containerWidth / 2 - 5,
            height: _this.containerheight / 2 - 5,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     * 停止播放所有流
     */
    stopAll() {
      let i = 0;
      while (i < 4) {
        this.stop(i);
        i++;
      }
    },

    /**
     * 销毁流
     */
    destroyAll() {
      let i = 0;
      while (i < 4) {
        this.destroy(i);
        i++;
      }
    },

    /**
     * 停止单个
     */
    stop(index) {
      if (this["player" + index + 1]) {
        return this["player" + index + 1].stop();
      }
    },

    /**
     * 销毁单个
     */
    destroy(index) {
      return this["player" + index + 1].destroy();
    },
    // destroy(index) {
    //   let destroyPromise = this["player" + index + 1].destroy();
    //   destroyPromise.then((data) => {
    //     console.log("promise 获取 数据", data)
    //   })
    // },

    /**
     * 切换视频
     */
    stopAndPlay(index, item) {
      this.stop(index).then(() => {
        this.play(index, item);
      });
    },
  },
};
</script>
<style lang="scss">
#video-list-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.video-container {
  margin-bottom: vh(10);
  width: 100%;
  height: 100%;
}
</style>
