<template>
  <div class="content">
    <div class="video" v-show="videoData.id">
      <video v-if="showVideo" class="videolist" :id="videoData.id" controls muted @mouseenter="direction=true" @mouseleave="direction=false"></video>
      <div v-else :id="eleId" class="videolist" @mouseenter="direction=true" @mouseleave="direction=false"></div>
      <div v-if="direction" @mouseenter="direction=true" class="video-direction">
        <div class="pictureZoom" @click="startDirection(10)">
          <el-button type="text"><i class="el-icon-zoom-in zoom-color-icon"></i></el-button>
        </div>
        <div class="pictureZoom" @click="startDirection(11)">
          <el-button type="text"><i class="el-icon-zoom-out zoom-color-icon"></i></el-button>
        </div>
        <!-- 关闭按钮 -->
        <!-- <div class="close-btn" @click="direction=false"><i class="el-icon-close close-icon"></i></div> -->
        <div class="video-direction-btn">
          <!-- 上 -->
          <div class="video-direction-btn-top">
            <el-button @click="startDirection(0)" type="text"><i class="el-icon-caret-top direction-icon"></i></el-button>
          </div>
          <div class="video-direction-btn-left_right">
            <!-- 左 -->
            <el-button @click="startDirection(2)" type="text"><i class="el-icon-caret-left direction-icon"></i></el-button>
            <el-button @click="stopDirection" type="text" class="video-direction-central-circle">
              <i class="el-icon-video-pause"></i>
            </el-button>
            <!-- 右 -->
            <el-button @click="startDirection(3)" type="text"><i class="el-icon-caret-right direction-icon"></i></el-button>
          </div>
          <!-- 下 -->
          <div class="video-direction-btn-bottom">
            <el-button @click="startDirection(1)" type="text"><i class="el-icon-caret-bottom direction-icon"></i></el-button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

  <script>
import { $_ptzStart, $_ptzStop } from "@/api/videoPage/videoPage"
const Hls = require("hls.js");
export default {
  props: {
    BigVideoData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      pluginnamearr: "",
      eleId: `id-${Math.random().toString().slice(2)}`,
      hlsObj: [],
      hls: null,
      player: null,
      videoData: { id: '' },
      showVideo: false,
      direction: true,//是否显示方向盘
      test: null,
    };
  },
  mounted() {
    setTimeout(() => {
      // this.handleRealplay();
    }, 1000);
  },
  /* 页面销毁时  清除播放流 */
  destroyed() {
    if (this.hls) this.hls.destroy();
  },
  // 这里来调用不要写在 beforeUpdate
  watch: {
    videoData() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.videoData.id) {
            this.handleRealplay();
          }
        }, 300);
      });
    },
    BigVideoData() {
      this.videoData = this.BigVideoData
    }
  },
  methods: {
    handleRealplay() {
      // zhe下面的可能是2种不同的流播放
      const { eleId, wsUrlA } = this.videoData;
      const playVFn = () => {
        this.showVideo = true;
        this.$nextTick(() => {
          this.videoPlay();
        });
      };
      if (!wsUrlA) {
        playVFn();
        return;
      }
      const curIndex = 0; // 当前窗口下标
      this.player = new window.JSPlugin({
        szId: eleId, // 需要英文字母开头 必填
        szBasePath: "./", // 必填,引用H5player.min.js的js相对路径
        // 当容器div#play_window有固定宽高时，可不传iWidth和iHeight，窗口大小将自适应容器宽高
        // iWidth: 600,
        // iHeight: 400,
        iMaxSplit: 1, // 分屏播放，默认最大分屏4*4
        iCurrentSplit: 1,
        openDebug: true,
        oStyle: {
          borderSelect: "#FFCC00",// 样式
        },
      });
      // wsUrlA,流媒体播放时必传
      // mode: 0  解码类型：0=普通模式; 1=高级模式 默认为0
      // curIndex 当前窗口下标
      this.player.JS_Play(wsUrlA, { playURL: wsUrlA, mode: 0, }, curIndex).then(() => {
        console.log("realplay success");
      }, (e) => {
        console.error("播放错误", e);
        playVFn();
      });
    },

    videoPlay() {
      //  这句代码就是可以销毁 自己问了写
      let video = document.getElementById(this.videoData.id);
      let videoSrc = this.videoData.url;
      if (!videoSrc) {
        // this.$message.success("请选择摄像头播放：HLS");
        return;
      }
      if (Hls.isSupported()) {
        if (!this.hls) {
          this.hls = new Hls();
        }
        this.hls.loadSource(videoSrc);
        this.hls.attachMedia(video);
        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play();
        });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = videoSrc;
      }
    },

    /* 方向按钮 */
    startDirection(val) {
      if (this.test != null) {
        Promise.all([this.stopDirection()]).then(() => {
          this.startTest(val);
        });
      } else {
        this.startTest(val);
      }
    },

    /* 开始云台控制 */
    startTest(val) {
      $_ptzStart({
        cameraId: this.videoData.cameraId,
        direction: val,
        speed: 1,
      }).then((res) => {
        setTimeout(() => {
          this.stopDirection();
        }, 500);
      }).catch((res) => {
        this.$message.error("该设备不支持此操作");
      });
      this.test = val;
    },

    /* 停止云台控制 */
    stopDirection() {
      return new Promise((r, j) => {
        $_ptzStop({
          cameraId: this.videoData.cameraId,
          direction: this.test,
        }).then((res) => {
          r();
        }).catch((res) => {
          this.$message.error("该设备不支持此操作");
        });
      });
    },

  },
}
    </script>

  <style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
}
.video {
  width: 100%;
  height: 100%;
  position: relative;
}
.videolist {
  width: 100%;
  height: 100%;
}
.video-direction {
  width: vh(160);
  height: vh(280);
  position: absolute;
  bottom: vh(102);
  right: vh(40);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .pictureZoom {
    background-color: rgba(255, 255, 255, 0.186);
    // background: #fff;
    // opacity: 0.3;
    width: vh(40);
    height: vh(40);
    display: flex;
    justify-content: center;
    align-items: center;
    .zoom-color-icon {
      color: #000;
    }
  }
  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: vh(20);
    height: vh(20);
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .close-icon {
      color: #fff;
      font-size: vh(10);
    }
  }
  .video-direction-btn {
    background-color: rgba(255, 255, 255, 0.186);
    // background: #fff;
    // opacity: 0.3;
    border-radius: 50%;
    width: vh(160);
    height: vh(160);
    .direction-icon {
      color: #000;
    }
    .video-direction-btn-top {
      height: vh(50);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .video-direction-btn-left_right {
      height: vh(60);
      display: flex;
      justify-content: space-around;
      align-items: center;
      .video-direction-central-circle {
        width: vh(35);
        height: vh(35);
        border-radius: 50%;
        border: 1px solid rgba(222, 222, 222, 0.528);
        font-size: vh(8);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .video-direction-btn-bottom {
      height: vh(50);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.video-direction:hover .pictureZoom,
.video-direction:hover .video-direction-btn {
  background-color: rgba(255, 255, 255, 0.500);
}
</style>
