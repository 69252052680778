var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    ref: "player",
    staticClass: "playWnd",
    attrs: { id: "player" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }